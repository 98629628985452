@import '../theme.scss';

.multiOptionFinanceAmountInput {
  &.active_currency {
    .closingBackground {
      display: block;
    }

    .input {
      .currentFlag {
        p {
          border-color: $color-blue;
        }
      }
    }

    .options_currency {
      max-height: 200px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      padding: 2 * $border-radius 0;
    }
  }

  &.active_amount {
    .closingBackground {
      display: block;
    }

    .input {
      .currentAmount {
        p {
          border-color: $color-blue;
        }

        .arrow {
          svg {
            rotate: 180deg;
          }
        }
      }
    }

    .options_amount {
      max-height: 200px;
      box-shadow: 0 1px 4px rgba(0, 0, 0, 0.3);
      padding: 2 * $border-radius 0;
    }
  }

  &.invalid {
    .input {
      .currentFlag {
        p {
          border-top-color: $color-red;
          border-bottom-color: $color-red;
          border-left-color: $color-red;
        }
      }

      .currentAmount {
        p {
          border-top-color: $color-red;
          border-bottom-color: $color-red;
          border-left-color: $color-red;
        }
      }
    }
  }

  &.initial {
    .input {
      p {
        color: #7a7a7a;
      }
    }
  }

  .input {
    background-color: $color-light-grey;
    border-radius: $border-radius;
    width: 100%;
    grid-template-columns: auto 1fr;
    grid-template-areas: 'flag input';

    .currentFlag {
      grid-area: flag;

      p {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.02em;
        padding: 1em 0.5em;
        outline: 0;
        border: none;
        border-radius: $border-radius 0 0 $border-radius;
        border: 1px solid rgba(0, 0, 0, 0);
        border-right-color: $color-medium-grey;
        background-color: $color-light-grey;
        transition: all 0.2s ease-in-out;
        position: relative;
        cursor: pointer;
        z-index: 2;
        width: 100%;
        text-align: left;
        color: $color-black;
      }
    }

    .currentAmount {
      grid-area: input;
      width: 100%;

      p {
        font-size: 14px;
        line-height: 1.5;
        letter-spacing: 0.02em;
        padding: 1em 0.5em;
        outline: 0;
        border: none;
        border-radius: 0 $border-radius $border-radius 0;
        border: 1px solid rgba(0, 0, 0, 0);
        background-color: $color-light-grey;
        transition: all 0.2s ease-in-out;
        position: relative;
        cursor: pointer;
        z-index: 2;
        width: 100%;
        text-align: left;
        overflow: hidden;
      }

      .arrow {
        top: 50%;
        transform: translateY(-50%);
        position: absolute;
        right: 7px;
        z-index: 2;
        left: unset;

        svg {
          transition: rotate 0.2s ease-in-out;
          rotate: 0deg;
        }
      }
    }
  }

  .options_currency,
  .options_amount {
    height: auto;
    max-height: 0px;
    overflow: hidden;
    overflow-y: scroll;
    transition: all 0.2s ease-in-out;
    background-color: $color-white;
    border-radius: 0 0 $border-radius $border-radius;
    position: absolute;
    width: 100%;
    top: 43px;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background: $color-white;
      margin-top: 2 * $border-radius;
    }

    &::-webkit-scrollbar-thumb {
      background: $color-medium-grey;
      border-radius: $border-radius;
    }

    ul {
      .option {
        padding: 0 5px;
        cursor: pointer;

        .flag,
        .amount {
          display: inline-block;
          vertical-align: middle;
          position: relative;
          width: 100%;
          font-size: 14px;
          line-height: 1.25;
          letter-spacing: 0.02em;
          color: $color-black;
          padding: 0.5em 0.5em;
          transition: background 0.2s ease-in-out;
          border-radius: $border-radius;

          img {
            display: inline-block;
            vertical-align: middle;
            translate: 0 -1px;
          }

          .currency {
            position: absolute;
            right: 0.5em;
          }
        }

        &:hover {
          .flag,
          .amount {
            background-color: $color-light-grey;
          }
        }
      }
    }
  }
}
